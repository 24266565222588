import axios, { AxiosError, type AxiosInstance, type AxiosRequestConfig, type AxiosResponse } from "axios";
import { useAuthStore } from "@/stores/auth";
import { useCounterStore } from "@/stores/counter";
import { NotificationItemType } from "./NotificationItem";
import router from "@/router";
class ApiService<T> {
  private axiosInstance: AxiosInstance;
  private authStore = useAuthStore();
  private store = useCounterStore();
  private requestConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.authStore.signUpResult?.jwtToken,
    },
  };

  _dontShowErrorMessage = false;
  get dontShowErrorMessage() {
    return this._dontShowErrorMessage;
  }
  set dontShowErrorMessage(value) {
    this._dontShowErrorMessage = value;
  }

  constructor(timeout = 20000) {
    this.axiosInstance = axios.create({
      baseURL: this.store.apiUrl,
      timeout,
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getUrl(): string {
    // return "https://localhost:7225";
    return "https://api.getwinner.app";
  }

  // Generic GET request
  public async get(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await this.axiosInstance.get<T>(endpoint, config);
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Generic POST request
  public async post(endpoint: string, data: any, config?: AxiosRequestConfig | null): Promise<AxiosResponse<T>> {
    try {
      const response = await this.axiosInstance.post<T>(endpoint, data, config === null ? this.requestConfig : undefined);
      if (response && response.data) {
        const data = response.data as any;
        if (data.error) {
          this.store.addNotification("Error", data.error, NotificationItemType.Error);
        }
      }
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Generic PUT request
  public async put(endpoint: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await this.axiosInstance.put<T>(endpoint, data, config);
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Generic DELETE request
  public async delete(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    try {
      const response = await this.axiosInstance.delete<T>(endpoint, config);
      return response;
    } catch (error) {
      this.handleError(error);
      throw error;
    }
  }

  // Handle errors
  private handleError(error: any): void {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;
      if (error && error.response && error.response.data) {
        const error400 = error.response.data as Error400;
        if (error400) {
          this.store.addNotification("Error", error400.title, NotificationItemType.Error, this.displayErrors(error400));
        }
      } else {
        if (axiosError.response && axiosError.response.status === 401) {
          this.store.addNotification("Error", "Unauthorized access. Please signin again.", NotificationItemType.Error);
          router.push("/");
        } else {
          this.store.addNotification("Error", axiosError.message, NotificationItemType.Error);
        }
      }
    } else {
      this.store.addNotification("Error", error.toString(), NotificationItemType.Error);
    }
  }

  private displayErrors(errorResponse: Error400): string[] | undefined {
    const { title, errors } = errorResponse;
    if (errors === undefined) {
      return undefined;
    }
    const errorMessages = [];

    // Iterate through each field's errors and concatenate them into the message
    for (const field in errors) {
      if (errors.hasOwnProperty(field)) {
        const fieldErrors = errors[field];
        const formattedErrors = fieldErrors.join(", ");
        errorMessages.push(`${formattedErrors}`);
      }
    }

    return errorMessages;
  }
}

interface Error400 {
  type: string;
  title: string;
  status: number;
  errors: {
    [key: string]: string[];
  };
  traceId?: string;
}

export default ApiService;
